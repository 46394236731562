<template>
  <InitLayout>
    <b-card
      no-body
      style="justify-self: center; align-self: center"
      class="d-flex flex-column align-items-center bg-transparent border-0 rounded-0 px-1 px-lg-4 login-container-card h-100"
    >
      <div v-if="!accepted" style="margin-top: 30vh; width: 100%">
        <b-row
          v-if="!businessName && isLoader"
          class="d-flex flex-row align-items-center justify-content-center w-100 h-100"
          no-gutters
        >
          <b-overlay :show="true" rounded="lg"></b-overlay>
        </b-row>
        <b-row
          v-if="errorInvite.status === true"
          class="d-flex flex-row align-items-center justify-content-center w-100 h-100"
          no-gutters
        >
          {{ errorInvite.message }}
        </b-row>
        <b-row
          v-if="businessName"
          class="d-flex flex-row align-items-center justify-content-center w-100 h-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <h4 class="text-center font-weight-light">
              You have received an invite from
            </h4>
            <h4
              class="text-center text-main-green font-primary font-weight-bold mt-3"
            >
              {{ businessName }}
            </h4>
            <h4 class="text-center mt-3">
              By
              <span class="text-main-green font-primary font-weight-bold">
                {{ invitedBy }}: {{ role }}</span
              >
            </h4>
            <div class="d-flex justify-content-center align-items-center">
              <FormButton
                variant="danger"
                isPill
                class="font-weight-normal text-secondary mt-4 mx-2 px-5 py-2"
                :isLoading="isLoader"
                style="background-color: #eb5757"
                @click.native="decline()"
                ><h6 class="text-white m-0">Decline</h6></FormButton
              >
              <FormButton
                variant="secondary"
                isPill
                class="font-weight-normal text-secondary mt-4 mx-2 px-5 py-2"
                :isLoading="isLoader"
                style="background-color: #0b9b7d; border-color: #0b9b7d"
                @click.native="accept()"
                ><h6 class="text-white m-0">Accept</h6></FormButton
              >
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row
        class="d-flex flex-row align-items-center justify-content-center w-100"
        no-gutters
        v-else
        style="margin-top: 35vh"
      >
        <b-col cols="12" md="10">
          <h4
            class="text-center text-main-green font-primary font-weight-bold mt-3"
          >
            Invitation Accepted!
          </h4>
        </b-col>
        <b-col cols="12" md="10">
          <span class="text-left text-gray-750 font-primary font-weight-normal">
            Let's create a password for you
          </span>
        </b-col>
        <br />
        <br />
        <b-col cols="12" md="10">
          <FormInputGroup
            groupId="password-group"
            id="password-group-input"
            v-model="new_password"
            :state="passwordError.status"
            :invalidFeedback="`${passwordError.message}`"
            label="New Password"
            placeholder="Minimum 6 characters"
            isRequired
            :whiteBG="false"
            :type="showPassword ? 'text' : 'password'"
            form="login-form"
            isAppend
          >
            <template v-slot:append>
              <b-button
                class="px-3 px-md-4 rounded-0"
                variant="transparent"
                @click="showPassword = !showPassword"
              >
                <b-icon
                  :icon="showPassword ? 'eye-slash' : 'eye'"
                  aria-label="Help"
                ></b-icon>
              </b-button>
            </template>
          </FormInputGroup>
        </b-col>
        <b-col cols="12" md="10">
          <FormInputGroup
            groupId="password-group"
            id="password-group-input"
            v-model="new_password2"
            :state="passwordError2.status"
            :invalidFeedback="`${passwordError2.message}`"
            label="Confirm Password"
            placeholder="Minimum 6 characters"
            isRequired
            :whiteBG="false"
            :type="showPassword2 ? 'text' : 'password'"
            form="login-form"
            isAppend
          >
            <template v-slot:append>
              <b-button
                class="px-3 px-md-4 rounded-0"
                variant="transparent"
                @click="showPassword2 = !showPassword2"
              >
                <b-icon
                  :icon="showPassword2 ? 'eye-slash' : 'eye'"
                  aria-label="Help"
                ></b-icon>
              </b-button>
            </template>
          </FormInputGroup>
        </b-col>
        <FormButton
          variant="secondary"
          isPill
          class="font-weight-normal text-secondary mt-4 mx-2 px-5 py-2"
          :isLoading="isLoader"
          style="background-color: #0b9b7d; border-color: #0b9b7d"
          :is-disabled="!passwordError.status || !passwordError2.status"
          @click.native="resetPassword"
          ><h6 class="text-white m-0">Sign in</h6></FormButton
        >
      </b-row>
    </b-card>
  </InitLayout>
</template>

<script>
import InitLayout from "@/layout/InitLayout";
import FormButton from "@/components/Form/FormButton";
import FormInputGroup from "@/components/Form/FormInputGroup";
import { acceptInvitation } from "@/services/invitations.service";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Invitation",
  components: {
    InitLayout,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isLoader: false,
      businessName: null,
      invitedBy: null,
      role: null,
      errorInvite: {
        status: null,
        message: null,
      },
      email: null,
      password: null,
      showPassword: false,
      showPassword2: false,
      new_password: null,
      new_password2: null,
      passwordError: {
        status: null,
        message: "",
      },
      passwordError2: {
        status: null,
        message: "",
      },
      accepted: false,
    };
  },
  async mounted() {
    this.isLoader = true;
    this.errorInvite.status = decodeURIComponent(await this.getCookie("error"));
    this.errorInvite.message = decodeURIComponent(
      await this.getCookie("message")
    );
    this.email = decodeURIComponent(await this.getCookie("email"));
    this.password = decodeURIComponent(await this.getCookie("password"));
    this.role = decodeURIComponent(await this.getCookie("role"));
    this.invitedBy = decodeURIComponent(await this.getCookie("inviter"));
    this.businessName = decodeURIComponent(await this.getCookie("business"));
    this.isLoader = false;
  },
  methods: {
    ...mapActions({
      localLogIn: "auth/localLogIn",
      workspaceLogin: "auth/workspaceLogin",
      setIsAuthenticated: "auth/setIsAuthenticated",
      setAccessToken: "auth/setAccessToken",
    }),
    ...mapGetters({
      getDefaultBusinessId: "auth/getDefaultBusinessId",
    }),
    accept() {
      console.log("accepted");
      this.accepted = true;
    },
    decline() {
      console.log("declined");
      window.close();
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    },
    deleteCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    async resetPassword() {
      const payload = {
        email: this.email,
        password: this.new_password,
      };
      await acceptInvitation(payload)
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            this.localLogIn(payload).then(async () => {
              await this.workspaceLogin(this.getDefaultBusinessId());
              this.$router.push("/");
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    new_password(value) {
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
      if (value !== this.new_password2) {
        this.passwordError2.status = false;
        this.passwordError2.message = "Password should match the above one";
      }
    },
    new_password2(value) {
      if (value) {
        if (value.length < 6) {
          this.passwordError2.status = false;
          this.passwordError2.message =
            "A minimum length of 6 characters needed";
        } else if (value !== this.new_password) {
          this.passwordError2.status = false;
          this.passwordError2.message = "Password should match the above one";
        } else {
          this.passwordError2.status = true;
        }
      } else {
        this.passwordError2.status = false;
        this.passwordError2.message = "Please fill in your password";
      }
    },
  },
};
</script>

<style scoped>
.login-container-card {
  width: 70%;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
}

.login-container-card .login-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.login-container-card .login-button:hover {
  opacity: 0.9;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
}

.login-container-card .login-google-button {
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.login-container-card .login-google-button:hover {
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
  color: var(--light);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
  }
}
</style>
